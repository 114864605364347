import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'

// import { addDays } from 'date-fns'

// import DjangoService from '@/services/DjangoService'
Vue.use(Vuex)

const state = {
  events: [
    // { id: 10, title: 'All day event', date: new Date(), allDay: true },
    // { id: 20, title: 'Timed event', start: addDays(new Date(), 1) },
    // { id: 30, title: 'Timed event', start: addDays(new Date(), 2) }
  ],
  weekendsVisible: true,
  holidays: [],
  calendar: [],
  currentEmployee: {},
  digits: null,
  isAdmin: false,
  verificationCode: null
}

const getters = {
  events: state => state.events,
  weekendsVisible: state => state.weekendsVisible,
  holidays: state => state.holidays,
  calendar: state => state.calendar,
  isAdmin: state => state.isAdmin,
  digits: state => state.digits,
  verificationCode: state => state.verificationCode,
  currentEmployee: state => state.currentEmployee,
  isManager: state=> state.currentEmployee.manager
}

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions
  })
