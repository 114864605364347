const queryToString = (query) => {
    console.log(query)
    var result = ''
    const propertyNames = Object.getOwnPropertyNames(query)
    console.log(propertyNames)
    propertyNames.forEach(property => {
        const segment = property + '=' + String(query[property]) + '&'
        console.log('segment', segment)
        result = result.concat(segment)
    })
    console.log(result)
    return result
}

export default queryToString 