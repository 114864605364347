<template>
    <div class="container">
        <v-form action="" method="POST" @submit.prevent="auth">
            <v-card width="400" class="mx-auto mt-5">
                <v-card-title>
                    <h1>
                        Authentifizierung
                    </h1>
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field
                            label="Handynummer (letzte 4 Ziffern)"
                            prepend-icon="mdi-phone"
                            v-model="digits"
                            type="number"
                            :rules="digitRules"
                        />
                        <v-text-field
                            label="Bestätigungscode"
                            prepend-icon="mdi-lock"
                            v-model="verificationCode"
                            type="number"
                        />
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn 
                        color="success" 
                        @click="verify(digits)"
                        :active="digits.len === 4"
                    >Anfordern</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="info" type="submit">Bestätigen</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    methods: {
    ...mapActions([
      "verify",
      "authenticate",
    ]),
    auth() {
        this.authenticate(this.verificationCode).then(() => {
            this.$router.push({ name: 'calendar' })
        })
    }
    },
    data () {
    return {
        digits: '',
        digitRules: [
            value => !!value || 'erforderlich',
            value => value.length === 4 || '4 Ziffern'
        ],
        verificationCode: '',
        verificationRules: [
            value => !!value || 'Code eingeben',
            value => value.length === 5 || 'Code soll 5 stellig sein'
        ]
    }
  },
}
</script>