import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },
    {
        path: '/calendar',
        name: 'calendar',
        component: () => import('../views/Calendar.vue'),
        async beforeEnter(to, from, next) {
            console.log('beforeEnter: calendar')
            if (store.state.admin || store.state.currentEmployee.manager) {
                store.dispatch('retrieveEvents', {})    
            } else {
                store.dispatch('retrieveEvents', {employee: store.state.currentEmployee.id})
            }
            // store.dispatch('retrieveCalendar')
            // store.dispatch('retrieveHolidays')
            next()
        }
    },
    {
        path: '/whatsapp',
        name: 'whatsapp',
        component: () => import('../views/WhatsApp.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router