import * as Mutation from './mutation-types'
import { getEventIndexById } from './utils'

export default {
  [Mutation.CREATE_EVENT] (state, event) {
    return state.events.push(event)
  },
  [Mutation.UPDATE_EVENT] (state, updatedEvent) {
    const index = getEventIndexById(state, updatedEvent.id)

    if (index === -1) {
      return console.warn(`Unable to update event (id ${updatedEvent.id})`)
    }
    
    return state.events.splice(index, 1, {
      ...state.events[index],
      title: updatedEvent.title,
      start: updatedEvent.start,
      end: updatedEvent.end,
      date: updatedEvent.date
    })
  },
  [Mutation.DELETE_EVENT] (state, eventId) {
    const index = getEventIndexById(state, eventId)

    if (index === -1) {
      return console.warn(`Unable to delete event (id ${eventId})`)
    }
    
    return state.events.splice(index, 1)
  },
  [Mutation.SET_WEEKENDS_ENABLED] (state, enabled) {
    state.weekendsVisible = enabled
  },
  [Mutation.SET_EVENTS] (state, events) {
    return state.events = events
  },
  [Mutation.SET_HOLIDAYS] (state, holidays) {
    return state.holidays = holidays
  },
  [Mutation.SET_CALENDAR] (state, calendar) {
    return state.calendar = calendar
  },
  [Mutation.SET_DIGITS] (state, digits) {
    return state.digits = digits
  },
  [Mutation.SET_VERIFICATION_CODE] (state, verificationCode) {
    return state.verificationCode = verificationCode
  },
  [Mutation.SET_CURRENT_EMPLOYEE] (state, employee) {
    console.log('MUTATION: SET_CURRENT_EMPLOYEE', employee)
    return state.currentEmployee = employee
  },
  // [Mutation.SET_AVAILABILITIES] (state, availabilities) {
  //   return state.availabilities = availabilities
  // }
}
