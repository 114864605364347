<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list dense>
        <v-list-item
          v-for="item in navigationItems"
          :key="item.text"
          v-show="!item.protected || isAdmin"
          link
          :to="{ name: item.route }">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      clipped-left
      color="primary"
      dark
      dense
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-icon class="mx-4" large>
        mdi-umbrella
      </v-icon>
      <router-link :to="{ name: 'home' }" class="tw-text-white">
        <v-toolbar-title class="mr-12 align-center">
          <span class="title">DHL</span>
        </v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>
      <v-btn @click="toggleTheme" text rounded>{{ themeButton }}</v-btn>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import { mapGetters } from "vuex";

export default {
  name: 'App',

  components: {
    // HelloWorld,
  },

  data: () => ({
    drawer: null,
    navigationItems: [
      {
        icon: 'mdi-calendar',
        text: 'Übersicht',
        link: '/calendar',
        route: 'calendar',
        protected: false,
      },
      {
        icon: 'mdi-whatsapp',
        text: 'WhatsApp',
        link: '/whatsapp',
        route: 'whatsapp',
        protected: true,
      }
    ]
  }),
  computed: {
    ...mapGetters(["isAdmin", "isManager", "currentEmployee"]),
    themeButton() {
      if (this.$vuetify.theme.dark) {
        return 'hell'
      } else {
        return 'dunkel'
      }
    }
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      // this.$vuetify.theme.themes.dark.anchor = '#'
    }
  }
};
</script>
