import axios from 'axios'
import queryToString from './utils'

const apiClient = axios.create({
    // baseURL: 'http://localhost:8000',
    baseURL: 'https://manulaika.pythonanywhere.com',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 20000
})

export default {
    verify(payload) {
        // console.log('api-call: verify')
        return apiClient.post('/verify/', payload).then(response => {
            // console.log(response)
            // console.log(response.data)
            return response.data
        })
    },
    authenticate(payload) {
        // console.log('api-call: authenticate')
        return apiClient.post('/authenticate/', payload).then(response => {
            // console.log(response)
            // console.log(response.data)
            return response.data
        })
    },
    getEvents(query) {
        // console.log('api-call: getEvents')
        // console.log('query: ', query)
        var queryString = '?'
        if (query != null) {
            queryString += queryToString(query)
            // console.log(queryString)
        }
        return apiClient.get('/meta/events/'+ queryString).then(response => {
            // console.log(response)
            // console.log(response.data)
            return response.data
        })
    },
    postEvent(event) {
        // console.log('api-call: postEvent')
        // console.log(event)
        return apiClient.post('/meta/events/', event).then(response => {
            // console.log(response)
            // console.log(response.data)
            return response.data
        })
    },
    deleteEvent(eventId) {
        // console.log(eventId)
        return apiClient.delete('/meta/events/' + eventId).then(response => {
            // console.log(response)
            // console.log(response.data)
            return response.data
        })
    },
    getHolidays() {
        return apiClient.get('/meta/holidays/').then(response => {
            // console.log(response)
            // console.log(response.data)
            return response.data
        })
    },
    getCalendar() {
        return apiClient.get('/meta/calendars/').then(response => {
            // console.log(response)
            // console.log(response.data)
            return response.data
        })
    },
    // getAvailabilities() {
    //     return apiClient.get('/meta/availabilities/').then(response => {
    //         console.log(response)
    //         console.log(response.data)
    //         return response.data
    //     })
    // },
}
