import * as Mutation from './mutation-types'
import DjangoService from '@/services/DjangoService'
import { format } from 'date-fns'

export default {
  createEvent ({ state, commit }, event) {
    const formattedDate = format(event.date, 'yyyy-MM-dd')
    const e = state.currentEmployee.id || 2
    return DjangoService.postEvent({ date: formattedDate, employee: e}).then((data) => {
      console.log(data)
      return commit(Mutation.CREATE_EVENT, data)
    })
    
  },
  updateEvent ({ commit }, updatedEvent) {
    return commit(Mutation.UPDATE_EVENT, updatedEvent)
  },
  deleteEvent ({ commit }, eventId) {
    return DjangoService.deleteEvent(eventId).then(() => {
      return commit(Mutation.DELETE_EVENT, eventId)
    })
  },
  setweekendsVisible ({ commit }, enabled) {
    return commit(Mutation.SET_WEEKENDS_ENABLED, enabled)
  },
  retrieveHolidays({ commit }) {
    return DjangoService.getHolidays().then(data => {
      console.log(data)
      return commit(Mutation.SET_HOLIDAYS, data)
    })
  },
  retrieveEvents({ commit }, props) {
    return DjangoService.getEvents(props).then(data => {
      console.log(data)
      return commit(Mutation.SET_EVENTS, data)
    })
  },
  verify({ commit }, digits) {
    commit(Mutation.SET_DIGITS, digits)
    return DjangoService.verify({ digits }).then(({ verificationCode }) => {
      return commit(Mutation.SET_VERIFICATION_CODE, verificationCode)
    })
  },
  authenticate({ commit }, verificationCode) {
    commit(Mutation.SET_VERIFICATION_CODE, verificationCode)
    return DjangoService.authenticate({verificationCode}).then(({ employee }) => {
      return commit(Mutation.SET_CURRENT_EMPLOYEE, employee)
    })
  },
}
