export const CREATE_EVENT = 'CREATE_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const SET_WEEKENDS_ENABLED = 'SET_WEEKENDS_ENABLED'
export const SET_EVENTS = 'SET_EVENTS'
export const SET_HOLIDAYS = 'SET_HOLIDAYS'
export const SET_CALENDAR = 'SET_CALENDAR'
export const SET_CURRENT_EMPLOYEE = 'SET_CURRENT_EMPLOYEE'
export const SET_DIGITS = 'SET_DIGITS'
export const SET_VERIFICATION_CODE = 'SET_VERIFICATION_CODE'
